const getGuideSection = (campaignId) => {
    switch (campaignId) {
        case 'brianfellow_2023':
        case 'brianfellow_004':
            return (<ul>
                <li>12월 31일까지 디깅노트를 제출해 주세요.</li>
                <li>PC 환경에서 작성하시는 것을 권장드립니다.</li>
            </ul>);
        case 'brianfellow_003':
            return (<ul>
                <li>2월 12일(수)까지 디깅노트를 제출해 주세요.</li>
                <li>PC 환경에서 작성하시는 것을 권장드립니다.</li>
            </ul>);
    }
}

function BFDiggingNoteGuide(props) {
    const {campaignId} = props;
    return (
        <div className="bf-digging-note-guide">
            <h1>Digging Note</h1>
            <div className="guide-hello">
                <p>브라이언 펠로우 여러분, 환영합니다.</p>
            </div>
            <div className="guide-text">{getGuideSection(campaignId)}</div>
        </div>
    );
}

export default BFDiggingNoteGuide;